import React from 'react';
import SummaryApi from '../common';
import { toast } from 'react-toastify';

const AdminDeleteProduct = ({ productData, fetchdata }) => {

    const handleDeleteProduct = async () => {
        const confirmed = window.confirm("Are you sure to delete product?");
        if (confirmed) {
            try {
                const response = await fetch(SummaryApi.deleteProduct.url, {
                    method: SummaryApi.deleteProduct.method,
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ _id: productData._id })
                });

                const responseData = await response.json();

                if (responseData.success) {
                    toast.success(responseData?.message);
                    fetchdata();
                } else {
                    toast.error(responseData?.message);
                }
            } catch (error) {
                console.error("Error deleting product:", error);
                toast.error("Failed to delete product. Please try again later.");
            }
        }
    };

    return (
        <div className='flex items-center justify-end mt-2'>
            <button className='p-2 bg-red-100 hover:bg-red-600 rounded-full hover:text-white cursor-pointer' onClick={handleDeleteProduct}>
                Delete Product
            </button>
        </div>
    );
};

export default AdminDeleteProduct;
