const productCategory = [
    { id : 1, label : "Promotion", value : "promotion"},
    { id : 2, label : "Soin visage", value : "soin visage"},
    { id : 3, label : "Soin corps", value : "soin corps"},
    { id : 4, label : "Nos coffrets", value : "nos coffrets"},
    { id : 5, label : "Soin cheveux", value : "soin cheveux"},
    { id : 6, label : "Huiles", value : "huiles"},
   
]


export default productCategory