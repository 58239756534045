import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import SummaryApi from '../common';
import { useNavigate } from "react-router-dom";
import { Link } from 'react-router-dom'; 
import moment from 'moment';
import { FaCheck, FaTimes, FaFileAlt } from 'react-icons/fa'; // Import des icônes de FontAwesome
import './AllOrders.css';

const AllOrders = () => {
  const [allOrders, setAllOrders] = useState([]);
  const navigate = useNavigate();

  const fetchAllOrders = async () => {
    try {
      const fetchData = await fetch(SummaryApi.getAllOrders.url, {
        method: SummaryApi.getAllOrders.method,
        credentials: 'include'
      });

      const dataResponse = await fetchData.json();

      if (dataResponse.success) {
        setAllOrders(dataResponse.data);
      } else {
        toast.error(dataResponse.message);
      }
    } catch (error) {
      console.error('Error fetching all orders:', error);
      toast.error('Failed to fetch all orders');
    }
  };

  useEffect(() => {
    fetchAllOrders();
  }, []);

  const handleActionButtonClick = async (orderId, action) => {
    try {
      const orderUpdateRequest = await fetch(SummaryApi.updateOrderStatus.url, {
        method: SummaryApi.updateOrderStatus.method,
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ 
          orderId: orderId,
          status: action === 'validate' ? 'Validated' : 'Cancelled'
        })
      });

      const orderUpdateResponse = await orderUpdateRequest.json();

      if (orderUpdateResponse.success) {
        setAllOrders(orderUpdateResponse.data);
      } else {
        toast.error(orderUpdateResponse.message);
      }
    } catch (error) {
      console.error('Error updating order:', error);
      toast.error('Failed to update the order');
    }
  };

  return (
    <div className="content-area-table">
      <table>
        <thead> 
          <tr>
            <th>#</th>
            <th>Prénom</th>
            <th>Nom</th>
            <th>Adresse</th>
            <th>Code Postal</th>
            <th>Ville</th>
            <th>Téléphone</th>
            <th>Prix Total</th>
            <th>Statut</th> 
            <th>Date de Création</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {allOrders.map((order, index) => (
            <tr key={order._id}>
              <td>{index + 1}</td>
              <td>{order?.name}</td>
              <td>{order?.lastName}</td>
              <td>{order?.address}</td>
              <td>{order?.postalCode}</td>
              <td>{order?.city}</td>
              <td>{order?.phoneNumber}</td>
              <td>{order.totalPrice} Dt</td>
              <td>
                <div className="dt-status">
                  {order.status === 'Validated' && <span className="dt-status-dot dot-validated"></span>}
                  {order.status === 'Cancelled' && <span className="dt-status-dot dot-cancelled"></span>}
                  {order.status === 'Pending' && <span className="dt-status-dot dot-pending"></span>}
                  <span className="dt-status-text">
                    {order.status === 'Validated' ? 'Validée' : order.status === 'Cancelled' ? 'Annulée' : 'En cours'}
                  </span>
                </div>
              </td>
              <td>{moment(order.createdAt).format('LL')}</td>
              <td className="action-buttons">
                <button className="validate" onClick={() => handleActionButtonClick(order._id, 'validate')}>
                  <FaCheck />
                </button>
                <button className="cancel" onClick={() => handleActionButtonClick(order._id, 'cancel')}>
                  <FaTimes />
                </button>
                <Link to={`/orderdetails/${order._id}`} className="details-link">
                  <FaFileAlt/> 
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default AllOrders;
