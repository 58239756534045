import React, { useState } from 'react';
import SummaryApi from '../common';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

function PaiementForm({totalPrice, products}) {
  console.log('products =>', products);
  
  const user = useSelector(state => state?.user?.user);
  const navigate = useNavigate();

  const [adresseLivraisonIdentique, setAdresseLivraisonIdentique] = useState(true);
  const [moyenPaiement, setMoyenPaiement] = useState('Paiement à la livraison');
  const [data, setData] = useState({
    userId: user?._id,
    name: user?.name,
    lastName: user?.lastName,
    adresseEmail: user?.email,
    address: "",
    postalCode: "",
    city: "",
    phoneNumber: "",
    totalPrice: totalPrice,
    products: products
  });

  const handleAdresseLivraisonChange = () => {
    setAdresseLivraisonIdentique(!adresseLivraisonIdentique);
  };

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setData((prev) => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("FINAL FORM=", JSON.stringify(data));

    try {
      const dataResponse = await fetch(SummaryApi.addOrder.url, {
        method: SummaryApi.addOrder.method,
        headers: {
          "content-type": "application/json"
        },
        body: JSON.stringify(data)
      });

      const dataApi = await dataResponse.json();

      if (dataApi.success) {
        toast.success(dataApi.message);
        navigate("/historyclient"); // Redirection vers /historyclient après succès
      } else if (dataApi.error) {
        toast.error(dataApi.message);
      } else {
        toast.error("Please check orders");
      }
    } catch (error) {
      toast.error("An error occurred. Please try again.");
      console.error(error);
    }
  };

  return (
    <div style={styles.container}>
      <form onSubmit={handleSubmit} style={styles.form}>
        <h2 style={styles.heading}>Formulaire de Paiement</h2>
        <div style={styles.inputGroup}>
          <label style={styles.label}>Prénom:</label>
          <input type="text" name="prenom" onChange={handleOnChange} style={styles.input} value={user?.name} disabled />
        </div>
        <div style={styles.inputGroup}>
          <label style={styles.label}>Nom:</label>
          <input type="text" name="nom" onChange={handleOnChange} style={styles.input} value={user?.lastName} disabled />
        </div>
        <div style={styles.inputGroup}>
          <label style={styles.label}>Adresse email:</label>
          <input type="text" name="adresseEmail" onChange={handleOnChange} style={styles.input} value={user?.email} disabled />
        </div>
        <div style={styles.inputGroup}>
          <label style={styles.label}>Adresse:</label>
          <input type="text" name="address" onChange={handleOnChange} style={styles.input} />
        </div>
        <div style={styles.inputGroup}>
          <label style={styles.label}>Code Postal:</label>
          <input type="text" name="postalCode" onChange={handleOnChange} style={styles.input} />
        </div>
        <div style={styles.inputGroup}>
          <label style={styles.label}>Ville:</label>
          <input type="text" name="city" onChange={handleOnChange} style={styles.input} />
        </div>
        <div style={styles.inputGroup}>
          <label style={styles.label}>Téléphone:</label>
          <input type="text" name="phoneNumber" onChange={handleOnChange} style={styles.input} />
        </div>
        <div style={styles.inputGroup}>
          <label style={styles.label}>Moyen de Paiement:</label>
          <select name="moyenPaiement" value={moyenPaiement} onChange={handleOnChange} style={styles.select}>
            <option value="Paiement à la livraison">Paiement à la livraison</option>
            {/* Ajoutez d'autres options de paiement ici */}
          </select>
        </div>
        <button type="submit" style={{...styles.submitButton , backgroundColor: '#F76FB7'}} >Valider le Paiement</button>
      </form> 
      <div style={styles.links}>
        <div style={styles.leftLinks}>
          <div>
            <a href="/politique-remboursement" style={styles.link}>Politique de Remboursement</a>
          </div>
          <div>
            <a href="/politique-expedition" style={styles.link}>Politique d'Expédition</a>
          </div>
        </div>
        <div style={styles.rightLinks}>
          <div>
            <a href="/politique-confidentialite" style={styles.link}>Politique de Confidentialité</a>
          </div>
          <div>
            <a href="/conditions-utilisation" style={styles.link}>Conditions d'Utilisation</a>
          </div>
        </div>
      </div>
    </div>
  );
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
  },
  form: {
    maxWidth: '600px',
    width: '100%',
    padding: '20px',
    borderRadius: '8px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    backgroundColor: '#ffffff',
    marginBottom: '20px',
  },
  heading: {
    fontSize: '24px',
    marginBottom: '20px',
    textAlign: 'center',
    color: '#333333',
    textTransform: 'uppercase',
  },
  inputGroup: {
    marginBottom: '15px',
  },
  label: {
    fontSize: '16px',
    marginRight: '10px',
    color: '#555555',
    fontWeight: 'bold',
    display: 'block',
  },
  input: {
    width: '100%',
    padding: '10px',
    fontSize: '16px',
    border: '1px solid #cccccc',
    borderRadius: '4px',
    boxSizing: 'border-box',
  },
  select: {
    width: '100%',
    padding: '10px',
    fontSize: '16px',
    border: '1px solid #cccccc',
    borderRadius: '4px',
    boxSizing: 'border-box',
    backgroundColor: '#ffffff',
    color: '#555555',
  },
  submitButton: {
    width: '100%',
    padding: '12px',
    fontSize: '18px',
    color: '#ffffff',
    backgroundColor: '#007bff',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease',
  },
  links: {
    display: 'flex',
    justifyContent: 'center',
  },
  leftLinks: {
    marginRight: '50px',
  },
  rightLinks: {
    marginLeft: '50px',
  },
  link: {
    fontSize: '16px',
    color: '#007bff',
    textDecoration: 'none',
    marginRight: '20px',
    paddingBottom: '2px',
  },
};

export default PaiementForm;
