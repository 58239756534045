import moment from 'moment';
import PropTypes from "prop-types";
import {
  PieChart,
  Pie,
  Cell,
  Tooltip,
} from "recharts";

const toPercent = (filled, total) => {
  return (filled / total) * 100;
}

const AreaCard = ({ colors, cardInfo, kpi, dateRange }) => {
  let data = [
    { name: '', value: 0 },
    { name: '', value: 0 },
    { name: '', value: 0 }
  ];

  if(kpi) {  
    let firstValue = { name: '', value: 0 };
    if(kpi.length > 1) {
      firstValue = { name: kpi[1].name, value: (toPercent(kpi[1].value, kpi[0].value) / 100) * 360 };
    };
    let secondValue = { name: '', value: 0 };
    if(kpi.length > 2) {
      secondValue = { name: kpi[2].name, value: (toPercent(kpi[2].value, kpi[0].value) / 100) * 360 };
    };
    let thirdValue = { name: '', value: 0 };
    if(kpi.length > 3) {
      thirdValue = { name: kpi[3].name, value: (toPercent(kpi[3].value, kpi[0].value) / 100) * 360 }; 
    };
    
    data = [
      { name: firstValue.name, value: firstValue.value },
      { name: secondValue.name, value: secondValue.value },
      { name: thirdValue.name, value: thirdValue.value },
    ];
  }

  const renderTooltipContent = (value) => {
    return `${((value / 360) * 100).toFixed(2)} %`;
  };
  
  const title = cardInfo.title;
  const formattedDateRange = `(${moment(dateRange.start).format('Do MMM YY')} - ${moment(dateRange.end).format('Do MMM YY')})`;

  return (
    <div className="area-card">
      <div className="area-card-info">
        <h5 className="info-title">{title} <span style={{ marginLeft: '8px', color: '#7F7FCF', fontSize: '0.9em' }}>{formattedDateRange}</span></h5>
        <div className="info-value">{cardInfo.value}</div>
        <p className="info-text">{cardInfo.text}</p>
      </div>
      <div className="area-card-chart">
        <PieChart width={100} height={100}>
          <Pie
            data={data}
            cx={50}
            cy={45}
            innerRadius={20}
            fill="#e4e8ef"
            paddingAngle={0}
            dataKey="value"
            startAngle={-270}
            endAngle={150}
            stroke="none"
          >
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={colors[index % colors.length]}
              />
            ))}
          </Pie>
          <Tooltip formatter={renderTooltipContent} />
        </PieChart>
      </div>
    </div>
  );
};


export default AreaCard;

AreaCard.propTypes = {
  colors: PropTypes.array.isRequired,
  cardInfo: PropTypes.object.isRequired,
  kpi: PropTypes.array,
  dateRange: PropTypes.object.isRequired
};
