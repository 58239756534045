import React, { useState } from 'react'
import { MdModeEditOutline, MdDelete } from "react-icons/md";
import AdminEditProduct from './AdminEditProduct';
import AdminDeleteProduct from './AdminDeleteProduct';

const AdminProductCard = ({
    data,
    fetchdata
}) => {
    const [editProduct, setEditProduct] = useState(false);
    const [deleteProduct, setDeleteProduct] = useState(false); 

    const handleDeleteProduct = () => {
        console.log('Suppression du produit :', data.productName);
        setDeleteProduct(true); 
    };

    return (
        <div className='bg-white p-4 rounded '>
            <div className='w-40'>
                <div className='w-32 h-32 flex justify-center items-center'>
                    <img src={data?.image[0]} className='mx-auto object-fill h-full'/>
                </div>
                <h1 className='text-ellipsis line-clamp-2'>{data.name}</h1>

                <p className='font-semibold'>
                    {data.sellingPrice} DT
                </p>

                <div className='flex items-center justify-end mt-2'>
                    <div className='w-fit p-2 bg-green-100 hover:bg-green-600 rounded-full hover:text-white cursor-pointer mr-2'
                         onClick={() => setEditProduct(true)}>
                        <MdModeEditOutline/>
                    </div>
                    <div className='w-fit p-2 bg-red-100 hover:bg-red-600 rounded-full hover:text-white cursor-pointer'
                         onClick={handleDeleteProduct}>
                        <MdDelete/>
                    </div>
                </div>
            </div>

            {
                editProduct && (
                    <AdminEditProduct productData={data} onClose={() => setEditProduct(false)} fetchdata={fetchdata}/>
                )
            }
            {
                deleteProduct && (
                    <AdminDeleteProduct productData={data} onClose={() => setDeleteProduct(false)} fetchdata={fetchdata}/>
                )
            }

        </div>
    )
}

export default AdminProductCard;
