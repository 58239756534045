import React from 'react';
import { FaMapMarkerAlt, FaPhone, FaEnvelope, FaFacebookF, FaInstagram, FaTiktok, FaArrowRight } from 'react-icons/fa'; // Import des icônes depuis la bibliothèque React Icons
import './footer.css'; 

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer__inner">
        <div className="footer__section">
          <h3 className="footer__heading">À Propos</h3>
          <p>Découvrez une touche de nature avec notre marque tunisienne de produits cosmétiques. Fabriqués à partir d'ingrédients 100% naturels et biologiques, nos produits préservent votre beauté naturelle tout en prenant soin de votre peau. Optez pour une beauté authentique, inspirée par les richesses naturelles de la Tunisie.</p>
        </div>
        <div className="footer__section">
          <h3 className="footer__heading">Liens Rapides</h3>
          <ul className="footer__links">
            <li><a href="/">Accueil</a></li>
            <li><a href="/avisclient">Avis clients</a></li>
            <li><a href="/a-propos">À Propos</a></li>
            <li><a href="/contact">Contact</a></li>
          </ul>
        </div>
        <div className="footer__section">
          <h3 className="footer__heading">Nous Contacter</h3>
          <div className="footer__contact-info">
            <p><FaMapMarkerAlt /> Adresse: Bardo 1 , Cité riadh</p>
            <p><FaPhone /> Téléphone: 24 039 300 / 29 321 377 </p>
            <p><FaEnvelope /> Email: laboratoireheraextrabio@gmail.com </p>
          </div>
        </div>
      </div>
      <div className="footer__subscribe">
        <p>ABONNEZ-VOUS À NOS E-MAILS</p>
        <div className="footer__subscribe-input">
          <input type="email" placeholder="Votre adresse e-mail" />
          <button><FaArrowRight /></button>
        </div>
      </div>
      <div className="footer__social-icons">
        <a href="https://www.facebook.com/laboratoireheraextrabio"><FaFacebookF /></a>
        <a href="https://www.instagram.com/heraextrabio/"><FaInstagram /></a>
        <a href="lien-tiktok"><FaTiktok /></a>
      </div>
      <div className="footer__copyright">
        <p>&copy; {new Date().getFullYear()} , Hera Extra Bio commerce électronique</p>
      </div>
    </footer>
  );
};

export default Footer;
