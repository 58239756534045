// contact.js

import React from 'react';

function Contact() {
    const formStyle = {
        maxWidth: '400px',
        margin: '20px auto',
        padding: '20px',
        backgroundColor: '#fff',
        borderRadius: '8px',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)'
    };

    const h2Style = {
        fontSize: '25px',
        textAlign: 'center',
        marginBottom: '20px',
        color: '#333',
        fontFamily: 'Georgia, serif' // Remplacez "Arial" par la famille de polices de votre choix
    };
    

    const labelStyle = {
        
        display: 'block',
        fontWeight: 'bold',
        marginBottom: '5px'
    };

    const inputStyle = {
     display: 'block',
    fontWeight: 'bold',
    marginBottom: '5px',
    border: '1px solid #ccc',
    padding: '8px', // Ajustez la valeur de padding selon votre préférence
    borderRadius: '4px',
    width: '100%' // Cette propriété permettra à l'input de s'étendre sur toute la largeur disponible
        
    
    };

    const buttonStyle = {
        width: '100%',
        padding: '10px',
        backgroundColor: '#007bff',
        color: '#fff',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
        fontSize: '16px',
        transition: 'background-color 0.3s ease'
    };

    const buttonHoverStyle = {
        backgroundColor: '#0056b3'
    };

    return (
        <div>
            <div style={formStyle} className="get-in-touch-form">
                <h2 style={h2Style}>** Get in touch **</h2>
                <form>
                    <div className="form-group">
                        <label htmlFor="nom" style={labelStyle}>Nom :</label>
                        <input type="text" id="nom" name="nom" style={inputStyle} required />
                    </div>
                    <div className="form-group">
                        <label htmlFor="email" style={labelStyle}>Email :</label>
                        <input type="email" id="email" name="email" style={inputStyle} required />
                    </div>
                    <div className="form-group">
                        <label htmlFor="telephone" style={labelStyle}>Numéro de téléphone :</label>
                        <input type="tel" id="telephone" name="telephone" style={inputStyle} required />
                    </div>
                    <div className="form-group">
                        <label htmlFor="commentaire" style={labelStyle}>Commentaire :</label>
                        <textarea id="commentaire" name="commentaire" rows="4" style={inputStyle} required></textarea>
                    </div>
                    <button type="submit" style={buttonStyle} onMouseOver={(e) => e.target.style.backgroundColor = buttonHoverStyle.backgroundColor} onMouseOut={(e) => e.target.style.backgroundColor = buttonStyle.backgroundColor}>Envoyer</button>
                </form>
            </div>
        </div>
    );
}

export default Contact;
