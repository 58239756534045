import React from 'react'
import CategoryList from '../components/CategoryList'
import BannerProduct from '../components/BannerProduct'
import HorizontalCardProduct from '../components/HorizontalCardProduct'
import VerticalCardProduct from '../components/VerticalCardProduct'

const Home = () => {
  return (
    <div>
      <CategoryList/>
      <BannerProduct/>

      <HorizontalCardProduct isUserSuggestion={true} category={"suggestion"} heading={"Acheter encore une fois"}/>
      <HorizontalCardProduct category={"promotion"} heading={"Promotion"}/>
      <HorizontalCardProduct category={"soin visage"} heading={"Soin visage"}/>

      <VerticalCardProduct category={"soin corps"} heading={"Soin corps"}/>
      <VerticalCardProduct category={"nos coffrets"} heading={"Nos coffrets"}/>
      <VerticalCardProduct category={"soin cheveux"} heading={"Soin cheveux"}/>
      <VerticalCardProduct category={"huiles"} heading={"Huiles"}/>
    
    </div>
  )
}

export default Home