const { default: SummaryApi } = require("../common")

const fetchUserSuggestion = async(userId)=>{
    const response = await fetch(SummaryApi.fetchUserSuggestion.url + userId,{
        method : SummaryApi.fetchUserSuggestion.method,
        headers : {
            "content-type" : "application/json"
        }
    })

    const dataResponse = await response.json()

    return dataResponse
}

export default fetchUserSuggestion