import { useState, useEffect } from "react";
import SummaryApi from "../../../common";
import { toast } from "react-toastify";
import moment from 'moment';
import 'moment/locale/fr'; 

moment.locale('fr'); 

const AreaProgressChart = ({ dateRange }) => {
  const [mostOrderedProducts, setMostOrderedProducts] = useState([]);

  const fetchMostOrderedProducts = async () => {
    const url = new URL(SummaryApi.mostOrderedProducts.url);
    url.searchParams.append('startDate', dateRange[0].startDate);
    url.searchParams.append('endDate', dateRange[0].endDate);

    try {
      const fetchData = await fetch(url, {
        method: SummaryApi.mostOrderedProducts.method,
        credentials: 'include'
      });

      const dataResponse = await fetchData.json();

      if (dataResponse.success) {
        const data = dataResponse.data.map((product) => {
          return {
            id: product._id,
            name: product.productName,
            count: product.totalQuantity, 
          };
        });

        setMostOrderedProducts(data);
      } else {
        toast.error(dataResponse.message);
      }
    } catch (error) {
      console.error('Error fetching most ordered products:', error);
      toast.error('Failed to fetch most ordered products');
    }
  };

  useEffect(() => {
    fetchMostOrderedProducts();
  }, [dateRange]);

  const formattedDateRange = `(${moment(dateRange[0].startDate).format('Do MMM YY')} - ${moment(dateRange[0].endDate).format('Do MMM YY')})`;

  return (
    <div className="progress-bar">
      <div className="progress-bar-info">
        <h4 className="progress-bar-title">
          Articles les plus vendus 
          <span style={{ marginLeft: '8px', color: '#7F7FCF', fontSize: '0.8em' }}>{formattedDateRange}</span>
        </h4>
      </div>
      <div className="progress-bar-list">
        {mostOrderedProducts?.map((progressbar) => {
          return (
            <div className="progress-bar-item" key={progressbar.id}>
              <div className="bar-item-info">
                <p className="bar-item-info-name">{progressbar.name}</p>
                <p className="bar-item-info-value">
                  {progressbar.count}
                </p>
              </div>
              <div className="bar-item-full">
                <div
                  className="bar-item-filled"
                  style={{
                    width: `${progressbar.count}%`,
                  }}
                ></div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default AreaProgressChart;
