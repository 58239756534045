import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import SummaryApi from "../../../common";
import AreaCard from "./AreaCard";
import "./AreaCards.scss";

const initialOrdersKPI = [ 
  {
    name: 'Total',
    value: 0
  }, 
  {
    name: 'Validées',
    value: 0
  }, 
  {
    name: 'En cours',
    value: 0
  }, 
  {
    name: 'Annulées',
    value: 0
  }, 
];

const initialUsersKPI = [ 
  {
    name: 'Total',
    value: 0
  }, 
  {
    name: 'Actifs',
    value: 0
  }, 
  {
    name: 'Inactifs',
    value: 0
  }
];

const initialCategoriesKPI = [
  {
    name: '',
    value: 0
  },
  {
    name: '',
    value: 0
  }
];

const AreaCards = ({ dateRange }) => {
  const start = dateRange[0].startDate;
  const end = dateRange[0].endDate;
  const [categoriesKPI, setCategoriesKPI] = useState(initialCategoriesKPI);
  const [usersKPI, setUsersKPI] = useState(initialUsersKPI);
  const [ordersKPI, setOrdersKPI] = useState(initialOrdersKPI);

  const fetchOrdersKPI = async () => {
    const url = new URL(SummaryApi.getOrdersKPI.url);
    url.searchParams.append('startDate', start);
    url.searchParams.append('endDate', end);
    
    try {
      const fetchData = await fetch(url, {
        method: SummaryApi.getOrdersKPI.method,
        credentials: 'include'
      });

      const dataResponse = await fetchData.json();

      if (dataResponse.success) {
        setOrdersKPI(dataResponse.data);        
      } else {
        toast.error(dataResponse.message);
      }
    } catch (error) {
      console.error('Error fetching users KPI:', error);
      toast.error('Failed to fetch users KPI');
    }
  };

  const fetchUsersKPI = async () => {
    const url = new URL(SummaryApi.getUsersKPI.url);
    url.searchParams.append('startDate', start);
    url.searchParams.append('endDate', end);
    
    try {
      const fetchData = await fetch(url, {
        method: SummaryApi.getUsersKPI.method,
        credentials: 'include'
      });

      const dataResponse = await fetchData.json();

      if (dataResponse.success) {
        setUsersKPI(dataResponse.data);
      } else {
        toast.error(dataResponse.message);
      }
    } catch (error) {
      console.error('Error fetching orders KPI:', error);
      toast.error('Failed to fetch orders KPI');
    }
  };

  const fetchCategoriesKPI = async () => {
    const url = new URL(SummaryApi.getCategoriesKPI.url);
    url.searchParams.append('startDate', start);
    url.searchParams.append('endDate', end);
    
    try {
      const fetchData = await fetch(url, {
        method: SummaryApi.getCategoriesKPI.method,
        credentials: 'include'
      });

      const dataResponse = await fetchData.json();

      if (dataResponse.success) {
        const categoriesKPI = dataResponse.data.map((item) => {
          return {
            name: item._id,
            value: item.totalQuantity
          }
        });
        setCategoriesKPI(categoriesKPI);
      } else {
        toast.error(dataResponse.message);
      }
    } catch (error) {
      console.error('Error fetching categories KPI:', error);
      toast.error('Failed to fetch categories KPI');
    }
  };

  useEffect(() => {
    fetchOrdersKPI();
    fetchUsersKPI();
    fetchCategoriesKPI();
  }, [dateRange]);

  return (
    <section className="content-area-cards">
      <AreaCard
        colors={["#FD8FEB", "#9BCACC", "#EDF76C"]}
        cardInfo={{
          title: 'Catégories',
          value: categoriesKPI[1].name,
          text: 'est la catégorie la plus vendue'
        }}
        dateRange={{ start: start, end: end }}
        kpi={categoriesKPI}
      /> 
      <AreaCard
        colors={["#0AB33D", "#E41E1E"]}
        cardInfo={{
          title: 'Clients',
          value: usersKPI[0].value,
          text:`${usersKPI[1].value} clients actifs`
        }}
        dateRange={{start: start, end: end}}
        kpi={usersKPI}
      />
      <AreaCard
        colors={["#0AB33D", "#FFAA5B", "#DA0F0F"]}
        cardInfo={{
          title: 'Commandes',
          value: ordersKPI[0].value,
          text:`${ordersKPI[1].value} commandes confirmés`
        }}
        dateRange={{start: start, end: end}}
        kpi={ordersKPI}
      />
    </section>
  );
};

export default AreaCards;
