import { useState } from "react";
import { subDays  } from "date-fns";

import AreaTop from "../components/dashboard/areaTop/AreaTop";
import AreaCards from "../components/dashboard/areaCards/AreaCards";
import AreaCharts from "../components/dashboard/areaCharts/AreaCharts";
import AreaTable from "../components/dashboard/areaTable/AreaTable";

const Dashboard = () => {
  const [dateRange, setDateRange] = useState([
    {
      startDate: subDays (new Date(), 6),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const handleDateRangeChange = (newDateRange) => {
    setDateRange([newDateRange]);
  }
  
  return (
    <div className="content-area">
      <AreaTop dateRange={dateRange} handleDateRangeChange={(newDateRange) => handleDateRangeChange(newDateRange)}/>
      <AreaCards dateRange={dateRange} />
      <AreaCharts dateRange={dateRange} />
      <AreaTable dateRange={dateRange} />
    </div>
  );
};

export default Dashboard;
