import SummaryApi from "../common"
import { toast } from 'react-toastify'

const addToCart = async(e,id) =>{
    e?.stopPropagation()
    e?.preventDefault()

    const userCartProductsResponse = await fetch(SummaryApi.addToCartViewProduct.url, {
        method: SummaryApi.addToCartViewProduct.method,
        credentials: 'include',
        headers: {
            "content-type": 'application/json'
        },
    })

    const userCartProductsResponseData = await userCartProductsResponse.json()

    if (userCartProductsResponseData.success) {
            const userCartProducts = Array.from(userCartProductsResponseData.data)
            for(let userCartProduct of userCartProducts){
                if(userCartProduct.productId._id == id){
                    toast.error('Produit déjà dans le panier');
                    return
                }
            }
        }
    
    const response = await fetch(SummaryApi.addToCartProduct.url,{
    method : SummaryApi.addToCartProduct.method,
    credentials : 'include',
    headers : {
        "content-type" : 'application/json'
    },
    body : JSON.stringify(
        { productId : id }
    )
    })

    const responseData = await response.json()

    if(responseData.success){
        toast.success(responseData.message)
    }

    if(responseData.error){
        toast.error(responseData.message)
    }


    return responseData

}


export default addToCart