import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import SummaryApi from '../common';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import './HistoryClient.css';

const HistoryClient = () => {
    const user = useSelector(state => state?.user?.user);
    const userId = user?._id;
    const [ordersHistory, setOrdersHistory] = useState([]);

    const fetchOrderHistory = async (userId) => {
        try {
            const fetchData = await fetch(SummaryApi.getOrderByUserId.url + `${userId}`, {
                method: SummaryApi.getOrderByUserId.method,
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            const dataResponse = await fetchData.json();

            if (dataResponse.success) {
                if (dataResponse.data.length === 0) {
                    toast.info('Aucun historique de commandes disponible pour cet utilisateur.');
                } else {
                    setOrdersHistory(dataResponse.data);
                }
            } else {
                toast.error(dataResponse.message);
            }
        } catch (error) {
            console.error('Erreur lors de la récupération de toutes les commandes :', error);
            toast.error('Échec de la récupération de toutes les commandes');
        } 
    };

    useEffect(() => {
        if (userId) {
            fetchOrderHistory(userId);
        } else {
            toast.error('Aucun identifiant utilisateur spécifié.');
        }
    }, [userId]);

    return (
        <div className="content-area-table">
            <table>
                <thead> 
                    <tr>
                        <th>#</th>
                        <th>Numéro de commande</th>
                        <th>Nom du client</th>
                        <th>Adresse</th>
                        <th>Statut</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {ordersHistory.map((order, index) => (
                        <tr key={order._id}>
                            <td>{index + 1}</td>
                            <td>{order._id}</td>
                            <td>{order.name} {order.lastName}</td>
                            <td>{order.address}</td>
                            <td>
                                <div className="status">
                                    {order.status === 'Validated' && <span className="dot dot-validated"></span>}
                                    {order.status === 'Cancelled' && <span className="dot dot-cancelled"></span>}
                                    {order.status === 'Pending' && <span className="dot dot-pending"></span>}
                                    <span className="status-text">
                                        {order.status === 'Validated' ? 'Validé' : order.status === 'Cancelled' ? 'Annulée' : 'En attente'}
                                    </span>
                                </div>
                            </td>
                            <td>
                                <Link to={`/orderdetails/${order._id}`}>
                                    <button className={`button-${order.status.toLowerCase()}`}>Voir la commande</button>
                                </Link>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default HistoryClient;
