import "./AreaTop.scss";
import { useEffect, useRef, useState } from "react";
import "react-date-range/dist/styles.css"; 
import "react-date-range/dist/theme/default.css";
import { DateRange } from "react-date-range";
import fr from 'date-fns/locale/fr'; 

const AreaTop = ({ handleDateRangeChange, dateRange }) => {
  const [showDatePicker, setShowDatePicker] = useState(false);
  const dateRangeRef = useRef(null);

  const handleInputClick = () => {
    setShowDatePicker(true);
  };

  const handleClickOutside = (event) => {
    if (dateRangeRef.current && !dateRangeRef.current.contains(event.target)) {
      setShowDatePicker(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleOnChange = (item) => {
    handleDateRangeChange(item.selection);
  }

  return (
    <section className="content-area-top">
      <div className="area-top-l">
      </div>
      <div className="area-top-r">
        <div
          ref={dateRangeRef}
          className={`date-range-wrapper ${
            !showDatePicker ? "hide-date-range" : ""
          }`}
          onClick={handleInputClick}
        >
          <DateRange
            editableDateInputs={true}
            onChange={(item) => handleOnChange(item)}
            moveRangeOnFirstSelection={false}
            ranges={dateRange}
            showMonthAndYearPickers={false}
            locale={fr} 
          />
        </div>
      </div>
    </section>
  );
};

export default AreaTop;
