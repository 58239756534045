import React, { useState, useEffect } from 'react';
import videobardo from '../assest/banner/videobardo.mp4';

const Apropos = () => {
    const [isVideoPlaying, setIsVideoPlaying] = useState(true);
    const [videoHeight, setVideoHeight] = useState(400); // Hauteur de la vidéo par défaut

    useEffect(() => {
        const handleResize = () => {
            setVideoHeight(400); // Réglez ici la hauteur de la vidéo en pixels ou en pourcentage selon votre mise en page
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div style={styles.container}>
            <div style={styles.content}>
                <div style={styles.centered}>
                    <h2 style={styles.title}>HERA EXTRA BIO</h2>
                    <p style={styles.paragraph}>
                        HERA EXTRA BIO est une marque tunisienne de produits cosmétiques 100 % d’origine naturelle créée en 2018 et située à Ariana. La marque ne cesse depuis plus de 3 ans de vous offrir des gammes de produits à une qualité premium alliant efficacité, accessibilité et plaisir des sens. Hera Extra Bio exploite de manière optimale les richesses et les multiples ressources du terroir tunisien et a développé des soins cosmétiques et des remèdes naturels correspondant à chaque besoin et adaptés à toutes les catégories d'âges tout en valorisant les plantes et les huiles médicinales et essentielles tunisiennes. Elle travaille sur 3 volets : • Extraction des huiles végétales par première pression à froid • Distillation des eaux florales • Développement de gammes spécifiques à chaque type de peau.
                    </p>
                </div>
                <div style={{ ...styles.videoContainer, height: videoHeight }}>
                    {isVideoPlaying && (
                        <video 
                            width="100%"
                            height="100%"
                            controls 
                            autoPlay
                            style={styles.video}
                        >
                            <source src={videobardo} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    )}
                </div>
            </div>
        </div>
    );
}

const styles = {
    container: {
        fontFamily: 'Arial, sans-serif',
        fontSize: '16px',
        lineHeight: '1.6',
        margin: '20px',
        textAlign: 'center',
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden',
    },
    centered: {
        textAlign: 'center',
    },
    videoContainer: {
        width: '50%',
        position: 'relative',
        overflow: 'hidden',
    },
    video: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        objectFit: 'cover',
    },
    title: {
        color: '#333',
        fontSize: '32px',
        marginBottom: '10px',
        fontWeight: 'bold',
    },
    paragraph: {
        textAlign: 'justify',
        fontSize: '18px',
        lineHeight: '1.8',
    }
};

export default Apropos;
