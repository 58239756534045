import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import SummaryApi from '../common';
import moment from 'moment';
import { toast } from 'react-toastify';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';

const OrderDetails = () => {
  const [order, setOrder] = useState(null);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const params = useParams();
  
  const orderId = params?.orderId;

  const fetchAllOrders = async () => {
    try {
      const fetchData = await fetch(SummaryApi.getOrderById.url + `${orderId}`, {
        method: SummaryApi.getOrderById.method,
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      const dataResponse = await fetchData.json();

      if (dataResponse.success) {
        setOrder(dataResponse.data);
      } else {
        toast.error(dataResponse.message);
      }
    } catch (error) {
      console.error('Error fetching all orders:', error);
      toast.error('Échec de la récupération des commandes');
    }
  };

  useEffect(() => {
    if (orderId) {
      fetchAllOrders(orderId);
    }
  }, [orderId]);

  const goToPreviousImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? order.products.length - 1 : prevIndex - 1
    );
  };

  const goToNextImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === order.products.length - 1 ? 0 : prevIndex + 1
    );
  };

  // Définir une nouvelle police pour les titres
  const titleFont = 'Arial, sans-serif';

  const styles = {
    container: {
      maxWidth: '1200px',
      margin: '70px auto', 
      padding: '20px',
      border: '1px solid #ddd',
      borderRadius: '10px',
      backgroundColor: '#ffffff',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
      fontFamily: 'Arial, sans-serif',
    },
    header: {
      textAlign: 'center',
      fontWeight: 'bold',
      fontSize: '28px',
      marginBottom: '20px',
      color: '#000', // Couleur du titre en noir
      fontFamily: titleFont,
    },
    details: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      gap: '20px',
      marginBottom: '20px',
    },
    detailItem: {
      margin: '10px 0',
      fontSize: '16px',
      color: '#555',
    },
    boldDetailItem: {
      margin: '10px 0',
      fontSize: '16px',
      color: 'black',
      fontWeight: 'bold',
    },
    productImagesContainer: {
      position: 'relative',
      maxWidth: '100%',
      marginBottom: '20px',
    },
    productImage: {
      width: '100%',
      height: 'auto',
      display: 'block',
      borderRadius: '8px',
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    },
    arrowLeft: {
      position: 'absolute',
      top: '50%',
      left: '10px',
      transform: 'translateY(-50%)',
      cursor: 'pointer',
      zIndex: 1, 
    },
    arrowRight: {
      position: 'absolute',
      top: '50%',
      right: '10px',
      transform: 'translateY(-50%)',
      cursor: 'pointer',
      zIndex: 1,
    },
    quantitiesContainer: {
      padding: '10px',
      border: '1px solid #ddd',
      borderRadius: '10px',
      backgroundColor: '#f9f9f9',
      marginBottom: '20px',
    },
    quantityItem: {
      margin: '5px 0',
      fontSize: '16px',
      color: '#555',
      fontWeight: 'bold',
    },
    totalPrice: {
      fontWeight: 'bold',
      fontSize: '18px',
      marginTop: '20px',
      color: 'black',
    },
    orderDate: {
      fontWeight: 'bold',
      fontSize: '16px',
      marginTop: '10px',
      color: 'black',
    },
    productName: {
      fontSize: '14px', // Taille réduite
      fontWeight: 'bold',
      marginLeft: '3px',
      color: 'gray',
    },
    imagesTitleContainer: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '10px',
    },
  };

  return (
    <>
      {order && (
        <div key={order.id} style={styles.container}>
          <h2 style={styles.header}>Détails de la Commande</h2>
          <div style={styles.details}>
            <div>
              <p style={styles.boldDetailItem}><strong>Prénom :</strong> {order?.name}</p>
              <p style={styles.boldDetailItem}><strong>Nom :</strong> {order?.lastName}</p>
              <p style={styles.boldDetailItem}><strong>Adresse :</strong> {order?.address}</p>
              <p style={styles.boldDetailItem}><strong>Code postal :</strong> {order?.postalCode}</p>
              <p style={styles.boldDetailItem}><strong>Ville :</strong> {order?.city}</p>
              <p style={styles.boldDetailItem}><strong>Téléphone:</strong> {order?.phoneNumber}</p>
              <div style={styles.quantitiesContainer}>
                <strong>Produits :</strong>
                <ul>
                  {order.products.map((product, index) => (
                    <li key={index} style={styles.quantityItem}>
                      - {product.productId.name} (Quantités: {product.quantity})
                    </li>
                  ))}
                </ul>
              </div>
              <p style={styles.totalPrice}><strong>Prix Total:</strong> {order.totalPrice} Dt</p>
              <p style={styles.orderDate}><strong>Date de Commande :</strong> {moment(order.orderDate).format('Do MMMM YYYY, HH:mm:ss')}</p>
            </div>
            <div style={{ position: 'relative' }}>
              <div>
                <div style={styles.imagesTitleContainer}>
                  <strong>Images du Produit :</strong>
                  {order.products[currentImageIndex] && (
                    <p style={styles.productName}>
                      {order.products[currentImageIndex].productId.name}
                    </p>
                  )}
                </div>
                <div style={styles.productImagesContainer}>
                  <FiChevronLeft
                    style={styles.arrowLeft}
                    onClick={goToPreviousImage}
                  />
                  <div style={styles.productImageContainer}>
                    {order.products[currentImageIndex] && (
                      <img
                        src={order.products[currentImageIndex].productId.image[0]}
                        alt={`Product ${currentImageIndex + 1}`}
                        style={styles.productImage}
                      />
                    )}
                  </div>
                  <FiChevronRight
                    style={styles.arrowRight}
                    onClick={goToNextImage}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default OrderDetails;
