import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import SummaryApi from "../../../common";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import "./AreaCharts.scss";

const getMonthName = (monthOrder) => {
  const date = new Date();
  date.setMonth(monthOrder - 1); 
  let monthName = date.toLocaleString('fr-FR', { month: 'long' });
  return monthName.charAt(0).toUpperCase() + monthName.slice(1);
};

const AreaBarChart = () => {
  const [monthsRevenues, setMonthsRevenues] = useState([
    {
      Mois: "NAN",
      Revenu: 0,
    }
  ]);

  const [totalRevenue, setTotalRevenue] = useState(0);

  const getMonthsRevenues = async () => {
    const url = new URL(SummaryApi.getMonthsRevenues.url);

    try {
      const fetchData = await fetch(url, {
        method: SummaryApi.getMonthsRevenues.method,
        credentials: 'include'
      });

      const dataResponse = await fetchData.json();

      if (dataResponse.success) {
        const revenues = dataResponse.data.revenues.map((monthItem) => {
          return {
            Mois: getMonthName(monthItem.month),
            Revenu: monthItem.totalRevenue
          };
        });

        setMonthsRevenues(revenues);
        setTotalRevenue(dataResponse.data.total);
      } else {
        toast.error(dataResponse.message);
      }
    } catch (error) {
      console.error('Error fetching last 9 months revenues:', error);
      toast.error('Failed to fetch last 9 months revenues');
    }
  };

  useEffect(() => {
    getMonthsRevenues();
  }, []);

  const formatTooltipValue = (value) => {
    return `${value}DT`;
  };

  const formatYAxisLabel = (value) => {
    return `${value}DT`;
  };

  const formatLegendValue = (value) => {
    return value.charAt(0).toUpperCase() + value.slice(1);
  };

  return (
    <div className="bar-chart">
      <div className="bar-chart-info">
        <h5 className="bar-chart-title">Revenu Total (9 derniers mois)</h5>
        <div className="chart-info-data">
          <div className="info-data-value">{totalRevenue} DT</div>
          <div className="info-data-text">
          </div>
        </div>
      </div>
      <div className="bar-chart-wrapper">
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            width={500}
            height={200}
            data={monthsRevenues}
            margin={{
              top: 5,
              right: 5,
              left: 0,
              bottom: 5,
            }}
          >
            <XAxis
              padding={{ left: 10 }}
              dataKey="Mois"
              tickSize={0}
              axisLine={false}
              tick={{
                fill: "#676767",
                fontSize: 14,
              }}
            />
            <YAxis
              padding={{ bottom: 10, top: 10 }}
              tickFormatter={formatYAxisLabel}
              tickCount={6}
              axisLine={false}
              tickSize={0}
              tick={{
                fill: "#676767",
              }}
            />
            <Tooltip
              formatter={formatTooltipValue}
              cursor={{ fill: "transparent" }}
            />
            <Legend
              iconType="circle"
              iconSize={10}
              verticalAlign="top"
              align="right"
              formatter={formatLegendValue}
            />
            <Bar
              dataKey="Revenu"
              fill="#475be8"
              activeBar={false}
              isAnimationActive={false}
              barSize={24}
              radius={[4, 4, 4, 4]}
            />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default AreaBarChart;
