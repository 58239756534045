import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import SummaryApi from '../../../common';
import moment from 'moment';
import 'moment/locale/fr'; 
import './AreaTable.scss'; 

moment.locale('fr'); 

const TABLE_HEADS = [
  "#",
  "Nom du client",
  "Téléphone",
  "Numéro de commande",
  "Montant",
  "Date",
  "Statut",
];

const AreaTable = ({ dateRange }) => {
  const [tableData, setTableData] = useState([]);

  const fetchAllOrders = async () => {
    const url = new URL(SummaryApi.getRecentOrders.url);
    url.searchParams.append('startDate', dateRange[0].startDate);
    url.searchParams.append('endDate', dateRange[0].endDate);
    
    try {
      const fetchData = await fetch(url, {
        method: SummaryApi.getRecentOrders.method,
        credentials: 'include'
      });

      const dataResponse = await fetchData.json();

      if (dataResponse.success) {
        setTableData(dataResponse.data);
      } else {
        toast.error(dataResponse.message);
      }
    } catch (error) {
      console.error('Error fetching all orders:', error);
      toast.error('Failed to fetch all orders');
    }
  };

  useEffect(() => {
    fetchAllOrders();
  }, [dateRange]);

  const formattedDateRange = `(${moment(dateRange[0].startDate).format('Do MMM YY')} - ${moment(dateRange[0].endDate).format('Do MMM YY')})`;

  return (
    <section className="content-area-table">
      <div className="data-table-info">
        <h4 className="data-table-title">
          Dernières commandes 
          <span style={{ marginLeft: '8px', color: '#7F7FCF', fontSize: '0.9em' }}>{formattedDateRange}</span>
        </h4>
      </div>
      <div className="data-table-diagram">
        <table>
          <thead>
            <tr>
              {TABLE_HEADS.map((th, index) => (
                <th key={index}>{th}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {tableData.map((dataItem, rowIndex) => (
              <tr key={dataItem._id}>
                <td>{rowIndex + 1}</td>
                <td>{dataItem.name} {dataItem.lastName}</td>
                <td>{dataItem.phoneNumber}</td>
                <td>{dataItem._id}</td>
                <td>{dataItem.totalPrice} Dt</td>
                <td>{moment(dataItem.createdAt).format('Do MMMM YYYY, HH:mm:ss')}</td>
                <td>
                  <div className="dt-status">
                    <span className={`dt-status-dot dot-${dataItem.status.toLowerCase()}`}></span>
                    <span className="dt-status-text">
                      {dataItem.status === 'Validated' ? 'Validée' : dataItem.status === 'Cancelled' ? 'Annulée' : 'En cours'}
                    </span>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </section>
  );
};

export default AreaTable;
