import { createBrowserRouter } from 'react-router-dom'
import App from '../App'
import Home from '../pages/Home'
import Login from '../pages/Login'
import ForgotPassowrd from '../pages/ForgotPassowrd'
import SignUp from '../pages/SignUp'
import AdminPanel from '../pages/AdminPanel'
import AllUsers from '../pages/AllUsers'
import AllProducts from '../pages/AllProducts'
import CategoryProduct from '../pages/CategoryProduct'
import ProductDetails from '../pages/ProductDetails'
import Cart from '../pages/Cart'
import SearchProduct from '../pages/SearchProduct'
import Formulaire from '../pages/formulaire'
import Contact from '../pages/contact'
import AllOrders from '../pages/AllOrders'
import Apropos from '../pages/apropos'
import OrderDetails from '../pages/OrderDetails'
import HistoryClient from '../pages/HistoryClient'
import Dashboard from '../pages/Dashboard'


const router = createBrowserRouter([
    {
        path : "/",
        element : <App/>,
        children : [
            {
                path : "dashboard",
                element : <Dashboard/>
            },
            {
                path : "",
                element : <Home/>
            },
            {
                path : "login",
                element : <Login/>
            },
            {
                path : "forgot-password",
                element : <ForgotPassowrd/>
            },
            {
                path : "sign-up",
                element : <SignUp/>
            },
            {
                path : "product-category",
                element : <CategoryProduct/>
            },
            {
                path : "product/:id",
                element : <ProductDetails/>
            },
            {
                path : 'cart',
                element : <Cart/>
            },
            {
                path : "orderdetails/:orderId",
                element : <OrderDetails/>
             },
             
            {
                path : "search",
                element : <SearchProduct/>
            },
            {
                path : "historyclient",
                element : <HistoryClient/>
             },
            {
                path : "formulaire",
                element : <Formulaire/>
            },
            {
                path : "contact",
                element : <Contact/>
            },
            {
                path : "a-propos",
                element : <Apropos/>
            },
            {
                path : "admin-panel",
                element : <AdminPanel/>,
                children : [
                    {
                        path : "dashboard",
                        element : <Dashboard/>
                    },
                    {
                        path : "all-users",
                        element : <AllUsers/>
                    },
                    {
                        path : "all-products",
                        element : <AllProducts/>
                    },
                    {
                        path : "all-orders",
                        element : <AllOrders/>
                    }
                ]
            },
        ]
    }
])


export default router