import React, { useContext, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import fetchCategoryWiseProduct from '../helpers/fetchCategoryWiseProduct';
import fetchUserSuggestion from '../helpers/fetchUserSuggestion';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa6';
import { Link } from 'react-router-dom';
import addToCart from '../helpers/addToCart';
import Context from '../context';

const VerticalCardProduct = ({ category, heading, isUserSuggestion = false }) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const loadingList = new Array(13).fill(null);
    const user = useSelector(state => state?.user?.user);
    const userId = user?._id;

    const scrollElement = useRef();

    const { fetchUserAddToCart } = useContext(Context);

    const handleAddToCart = async (e, id) => {
        await addToCart(e, id);
        fetchUserAddToCart();
    };

    async function fetchUserSuggestions() {
        setLoading(true);
        try {
            if(userId) { 
                const response = await fetchUserSuggestion(userId);
                const userSuggestions = response?.data.map((item) => {
                    return item.productDetails
                });
                setData(userSuggestions);
            } 
        } catch (error) {
            console.error('Error fetching user suggestions:', error);
        } finally {
            setLoading(false);
        }
    };

    async function fetchCategoryProducts() {
    setLoading(true);
    try {
        const response = await fetchCategoryWiseProduct(category); 
        setData(response?.data);
    } catch (error) {
        console.error('Error fetching category products:', error);
    } finally {
        setLoading(false);
    }
    };

    function fetchData() { 
        if (isUserSuggestion) { 
            fetchUserSuggestions();
        } else { 
            fetchCategoryProducts();
        };
    };

    useEffect(() => {
    fetchData();
    }, [category, isUserSuggestion, user]);

    const scrollRight = () => {
        scrollElement.current.scrollLeft += 300;
    };

    const scrollLeft = () => {
        scrollElement.current.scrollLeft -= 300;
    };

    return (
        <>
        {data && (<div className='container mx-auto px-4 my-6 relative'>
            <h2 className='text-2xl font-semibold py-4'>{heading}</h2>

            <div className='flex items-center gap-4 md:gap-6 overflow-x-scroll scrollbar-none transition-all' ref={scrollElement}>
                <button className='bg-white shadow-md rounded-full p-1 absolute left-0 text-lg hidden md:block' onClick={scrollLeft}><FaAngleLeft /></button>
                <button className='bg-white shadow-md rounded-full p-1 absolute right-0 text-lg hidden md:block' onClick={scrollRight}><FaAngleRight /></button>

                {loading ? (
                    loadingList.map((_, index) => (
                        <div key={index} className='w-full min-w-[280px] md:min-w-[320px] max-w-[280px] md:max-w-[320px] bg-white rounded-sm shadow'>
                            <div className='bg-slate-200 h-48 p-4 min-w-[280px] md:min-w-[145px] flex justify-center items-center animate-pulse'></div>
                            <div className='p-4 grid gap-3'>
                                <h2 className='font-medium text-base md:text-lg text-ellipsis line-clamp-1 text-black p-1 py-2 animate-pulse rounded-full bg-slate-200'></h2>
                                <p className='capitalize text-slate-500 p-1 animate-pulse rounded-full bg-slate-200 py-2'></p>
                                <div className='flex gap-3'>
                                    <p className='text-red-600 font-medium p-1 animate-pulse rounded-full bg-slate-200 w-full py-2'></p>
                                    <p className='text-slate-500 line-through p-1 animate-pulse rounded-full bg-slate-200 w-full py-2'></p>
                                </div>
                                <button className='text-sm text-white px-3 rounded-full bg-slate-200 py-2 animate-pulse'></button>
                            </div>
                        </div>
                    ))
                ) : (
                    data.map((product, index) => (
                        <Link key={index} to={`product/${product?._id}`} className='w-full min-w-[280px] md:min-w-[320px] max-w-[280px] md:max-w-[320px] bg-white rounded-sm shadow'>
                            <div className='text-white h-48 p-4 min-w-[280px] md:min-w-[145px] flex justify-center items-center'>
                                <img src={product.image[0]} className='object-scale-down h-full hover:scale-110 transition-all mix-blend-multiply' />
                            </div>
                            <div className='p-4 grid gap-3'>
                                <h2 className='font-medium text-base md:text-lg text-ellipsis line-clamp-1 text-black'>{product?.name}</h2>
                                <p className='capitalize text-slate-500'>{product?.category}</p>
                                <div className='flex gap-3'>
                                    <p className='text-red-600 font-medium'>{product?.sellingPrice}DT</p>
                                    {product?.category === 'promotion' && (
                                        <p className='text-slate-500 line-through'>{product?.price}DT</p>
                                    )}
                                </div>
                                <button className='text-sm  bg-pink-300 hover:bg-pink-500 text-white px-3 py-0.5 rounded-full' onClick={(e) => handleAddToCart(e, product?._id)}>Ajouter au panier</button>
                            </div>
                        </Link>
                    ))
                )}
            </div>
        </div>)
    }
    </>
    );
};

export default VerticalCardProduct;
