import React, { useContext, useEffect, useState } from 'react'
import SummaryApi from '../common'
import Context from '../context'
import { MdDelete } from "react-icons/md";
import PaiementForm from "./formulaire"

const Cart = () => {
    const [data, setData] = useState([])
    const [cart, setCart] = useState([])
    const [loading, setLoading] = useState(false)
    const [showPaiementForm, setShowPaiementForm] = useState(false); 
    const context = useContext(Context)
    const loadingCart = new Array(4).fill(null)

    const handleCartConfirmation = () => {
		setCart(formatCart(data))
        setShowPaiementForm(true)
    } 
  
	
	const formatCart = (data) => {
        return data.map(({ _id }) =>  _id  );
    }

 const fetchData = async () => {
        const response = await fetch(SummaryApi.addToCartViewProduct.url, {
            method: SummaryApi.addToCartViewProduct.method,
            credentials: 'include',
            headers: {
                "content-type": 'application/json'
            },
        })

        const responseData = await response.json()

        if (responseData.success) {
            setData(Array.from(responseData.data))
        }
    }

    const handleLoading = async () => {
        await fetchData()
    }

    useEffect(() => {
        setLoading(true)
        handleLoading()
        setLoading(false)
    }, [])

    const increaseQty = async (id, qty) => {
        const response = await fetch(SummaryApi.updateCartProduct.url, {
            method: SummaryApi.updateCartProduct.method,
            credentials: 'include',
            headers: {
                "content-type": 'application/json'
            },
            body: JSON.stringify(
                {
                    _id: id,
                    quantity: qty + 1
                }
            )
        })

        const responseData = await response.json()

        if (responseData.success) {
            fetchData()
        }
    }

    const decraseQty = async (id, qty) => {
        if (qty >= 2) {
            const response = await fetch(SummaryApi.updateCartProduct.url, {
                method: SummaryApi.updateCartProduct.method,
                credentials: 'include',
                headers: {
                    "content-type": 'application/json'
                },
                body: JSON.stringify(
                    {
                        _id: id,
                        quantity: qty - 1
                    }
                )
            })

            const responseData = await response.json()

            if (responseData.success) {
                fetchData()
            }
        }
    }

    const deleteCartProduct = async (id) => {
        const response = await fetch(SummaryApi.deleteCartProduct.url, {
            method: SummaryApi.deleteCartProduct.method,
            credentials: 'include',
            headers: {
                "content-type": 'application/json'
            },
            body: JSON.stringify(
                {
                    _id: id,
                }
            )
        })

        const responseData = await response.json()

        if (responseData.success) {
            fetchData()
            context.fetchUserAddToCart()
        }
    }

    const totalQty = data.reduce((previousValue, currentValue) => previousValue + currentValue.quantity, 0)
    const totalPrice = data.reduce((preve, curr) => preve + (curr.quantity * curr?.productId?.sellingPrice), 0)

    return (
        <div className='container mx-auto'>

            <div className='text-center text-lg my-3'>
                {
                    data.length === 0 && !loading && (
                        <p className='bg-white py-5'>Aucune donnée</p>
                    )
                }
            </div>
            

     <div className='flex flex-col lg:flex-row gap-10 lg:justify-between p-4'>
        <div className='w-full max-w-3xl'>
         {
        loading ? (
      loadingCart?.map((el, index) => {
      return (
      <div key={el + "Add To Cart Loading" + index} className='w-full bg-slate-200 h-32 my-2 border border-slate-300 animate-pulse rounded'>
          </div>
          )
              })
             ) : (
             data.map((product, index) => {
             return (
        <div key={product?._id + "Add To Cart Loading"} className='w-full bg-white h-32 my-2 border border-slate-300  rounded grid grid-cols-[128px,1fr]'>
        <div className='w-32 h-32 bg-slate-200'>
        <img src={product?.productId?.image[0]} className='w-full h-full object-scale-down mix-blend-multiply' />
           </div>
        <div className='px-4 py-2 relative'>
   {/**delete product */}
     <div className='absolute right-0 text-red-600 rounded-full p-2 hover:bg-red-600 hover:text-white cursor-pointer' onClick={() => deleteCartProduct(product?._id)}>
          <MdDelete />
     </div>

    <h2 className='text-lg lg:text-xl text-ellipsis line-clamp-1'>{product?.productId?.name}</h2>
     <p className='capitalize text-slate-500'>{product?.productId?.category}</p>
            <div className='flex items-center justify-between'>
         <p className='text-red-600 font-medium text-lg'>{(product?.productId?.sellingPrice)}DT</p>
        <p className='text-slate-600 font-semibold text-lg'>{(product?.productId?.sellingPrice * product?.quantity)}DT</p>
   </div>
     <div className='flex items-center gap-3 mt-1'>
  <button className='border border-black text-black hover:bg-red-600 hover:text-white w-6 h-6 flex justify-center items-center rounded ' onClick={() => decraseQty(product?._id, product?.quantity)}>-</button>
     <span>{product?.quantity}</span>
     <button className='border border-black text-black hover:bg-red-600 hover:text-white w-6 h-6 flex justify-center items-center rounded ' onClick={() => increaseQty(product?._id, product?.quantity)}>+</button>
     </div>
      </div>
          </div>
         )
         })
          )
          }
      </div>
    {/***summary  */}
 <div className='mt-5 lg:mt-0 w-full max-w-sm'>
      {
     loading ? (
      <div className='h-36 bg-slate-200 border border-slate-300 animate-pulse'>

      </div>
     ) : (
      <div className='h-36 bg-white'>
      <h2 className='text-white bg-pink-300 px-4 py-1'>Résumé</h2>
    
    <div className='flex items-center justify-between px-4 gap-2 font-medium text-lg text-black' style={{ marginTop: '10px' ,marginBottom:'-30px'}} >
     <p>Prix Total</p>
     <p>{(totalPrice)}DT</p>
            </div>

  <button className='bg-pink-400 p-2 text-white w-full mt-2'  style={{ marginTop: '65px' }} onClick={() => handleCartConfirmation()}>
        
    Paiement
         </button> 
        </div>
          )
         }
      </div>
            </div>
            {showPaiementForm && <PaiementForm products={cart} totalPrice={totalPrice}/>}
        </div>
    )
}

export default Cart
